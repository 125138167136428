export const EnumCodeScreen = {
    Dashboard: 1,
    CadastroEmpresas: 2,
    PlanosEmpresas: 3,
    Perfil: 4,
    SMS: 5,
    WhatsApp: 6,
    PlanoNfse: 7,
    FAQ: 8,
    Usuarios: 9,
    Alerts: 10,
    VenderPlano: 11,
    ReportPlanosVendidos:12
}
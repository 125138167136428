import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect, Router } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';
import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';
// import BaseHorizontal from './components/Layout/BaseHorizontal';
import PrivateRoute from './PrivateRoute'
import { history } from './history'
/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props} />;

const DashboardV1 = lazy(() => import('./components/Dashboard/DashboardV1'));
const DashboardV2 = lazy(() => import('./components/Dashboard/DashboardV2'));
const DashboardV3 = lazy(() => import('./components/Dashboard/DashboardV3'));

const ChartFlot = lazy(() => import('./components/Charts/ChartFlot'));
const ChartRadial = lazy(() => import('./components/Charts/ChartRadial'));
const ChartChartJS = lazy(() => import('./components/Charts/ChartChartJS'));
const ChartMorris = lazy(() => import('./components/Charts/ChartMorris'));
const ChartChartist = lazy(() => import('./components/Charts/ChartChartist'));

const Calendar = lazy(() => import('./components/Calendar/Calendar'));

const OfficeRegister = lazy(() => import('./components/Registrations/OfficeRegister'));
const SmsRegister = lazy(() => import('./components/Registrations/SMSRegister'));
const WhatsAppRegister = lazy(() => import('./components/Registrations/WhatsAppRegister'));
const PlanCompanyRegister = lazy(() => import('./components/Registrations/PlanCompanyRegister'));
const EmployeeRegister = lazy(() => import('./components/Registrations/EmployeeRegister'));
const PlanNfseRegister = lazy(() => import('./components/Registrations/PlanNfseRegister'));
const CostCenterRegister = lazy(() => import('./components/Registrations/CostCenterRegister'));
const UserRegister = lazy(() => import('./components/Registrations/UserRegister'));
const AlertsRegister = lazy(() => import('./components/Registrations/AlertsRegister'));
const FAQRegister = lazy(() => import('./components/Registrations/FAQRegister'));
const ProfilerRegister = lazy(() => import('./components/Registrations/Profiles/ProfilesRegister'));

const FinancialOperation = lazy(() => import('./components/Operations/Financial/FinancialOperation'));

const MapsGoogle = lazy(() => import('./components/Maps/MapsGoogle'));
const MapsVector = lazy(() => import('./components/Maps/MapsVector'));

const FormStandard = lazy(() => import('./components/Forms/FormStandard'));
const FormExtended = lazy(() => import('./components/Forms/FormExtended'));
const FormValidation = lazy(() => import('./components/Forms/FormValidation'));
const FormWizard = lazy(() => import('./components/Forms/FormWizard'));
const FormUpload = lazy(() => import('./components/Forms/FormUpload'));
const FormCropper = lazy(() => import('./components/Forms/FormCropper'));

const Login = lazy(() => import('./components/Pages/Login'));
const Register = lazy(() => import('./components/Pages/Register'));
const Recover = lazy(() => import('./components/Pages/Recover'));
const Lock = lazy(() => import('./components/Pages/Lock'));
const NotFound = lazy(() => import('./components/Pages/NotFound'));
const Error500 = lazy(() => import('./components/Pages/Error500'));
const Maintenance = lazy(() => import('./components/Pages/Maintenance'));
const SellPlans = lazy(() => import('./components/Operations/Sale/SellPlans'));
const PlansSold = lazy(() => import('./components/Reports/PlansSold/PlansSoldView'));
// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
    '/login',
    '/register',
    '/recover',
    '/lock',
    '/notfound',
    '/error500',
    '/maintenance'
];

const Routes = ({ location }) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };

    // Animations supported
    //      'rag-fadeIn'
    //      'rag-fadeInRight'
    //      'rag-fadeInLeft'

    const animationName = 'rag-fadeIn'

    if (listofPages.indexOf(location.pathname) > -1) {
        return (
            // Page Layout component wrapper
            <BasePage>
                <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                        <Route path="/login" component={waitFor(Login)} />
                        <Route path="/register" component={waitFor(Register)} />
                        <Route path="/recover" component={waitFor(Recover)} />
                        <Route path="/lock" component={waitFor(Lock)} />
                        <Route path="/notfound" component={waitFor(NotFound)} />
                        <Route path="/error500" component={waitFor(Error500)} />
                        <Route path="/maintenance" component={waitFor(Maintenance)} />

                        <Redirect to="/login" />
                    </Switch>
                </Suspense>
            </BasePage>
        )
    }
    else {
        return (
            // Layout component wrapper
            // Use <BaseHorizontal> to change layout
            <Base>
                <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <div>
                            <Suspense fallback={<PageLoader />}>
                                <Router history={history} >

                                    <Switch location={location}>

                                        {/* <Route path="/login" component={waitFor(Login)}/> */}
                                        {/*Dashboard*/}
                                        <PrivateRoute path="/dashboardv1" component={waitFor(DashboardV1)} />
                                        <Route path="/dashboardv2" component={waitFor(DashboardV2)} />
                                        <Route path="/dashboardv3" component={waitFor(DashboardV3)} />

                                        {/*Calender*/}
                                        <PrivateRoute path="/calendar" component={waitFor(Calendar)} />

                                        {/*Registrations*/}
                                        <PrivateRoute path="/OfficeRegister" component={waitFor(OfficeRegister)} />
                                        <PrivateRoute path="/SmsRegister" component={waitFor(SmsRegister)} />
                                        <PrivateRoute path="/WhatsAppRegister" component={waitFor(WhatsAppRegister)} />
                                        <PrivateRoute path="/planCompanyRegister" component={waitFor(PlanCompanyRegister)} />
                                        <PrivateRoute path="/EmployeeRegister" component={waitFor(EmployeeRegister)} />
                                        <PrivateRoute path="/PlanNfseRegister" component={waitFor(PlanNfseRegister)} />
                                        <PrivateRoute path="/faqregister" component={waitFor(FAQRegister)} />
                                        <PrivateRoute path="/profilerRegister" component={waitFor(ProfilerRegister)} />
                                        <PrivateRoute path="/CostCenterRegister" component={waitFor(CostCenterRegister)} />
                                        <PrivateRoute path="/userRegister" component={waitFor(UserRegister)} />
                                        <PrivateRoute path="/alertsRegister" component={waitFor(AlertsRegister)} />
                                        {/*Operations*/}
                                        <PrivateRoute path="/FinancialOperation" component={waitFor(FinancialOperation)} />
                                        <PrivateRoute path="/sellplans" component={waitFor(SellPlans)} />
                                        <PrivateRoute path="/planssold" component={waitFor(PlansSold)} />
                                        {/*Forms*/}
                                        <Route path="/form-standard" component={waitFor(FormStandard)} />
                                        <Route path="/form-extended" component={waitFor(FormExtended)} />
                                        <Route path="/form-validation" component={waitFor(FormValidation)} />
                                        <Route path="/form-wizard" component={waitFor(FormWizard)} />
                                        <Route path="/form-upload" component={waitFor(FormUpload)} />
                                        <Route path="/form-cropper" component={waitFor(FormCropper)} />

                                        {/*Charts*/}
                                        <Route path="/chart-flot" component={waitFor(ChartFlot)} />
                                        <Route path="/chart-radial" component={waitFor(ChartRadial)} />
                                        <Route path="/chart-chartjs" component={waitFor(ChartChartJS)} />
                                        <Route path="/chart-morris" component={waitFor(ChartMorris)} />
                                        <Route path="/chart-chartist" component={waitFor(ChartChartist)} />

                                        {/*Maps*/}
                                        <PrivateRoute path="/map-google" component={waitFor(MapsGoogle)} />
                                        <PrivateRoute path="/map-vector" component={waitFor(MapsVector)} />

                                        <Redirect to="/login" />
                                    </Switch>
                                </Router>
                            </Suspense>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </Base>
        )
    }
}

export default withRouter(Routes);